<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">用户列表</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

    <div style="display: flex">
      <div style="flex: 1">
        <v-text-field
          dense
          label="输入用户名查询"
          solo
          @change="changeSearch"
        ></v-text-field>
      </div>
      <div>
        <v-btn color="primary" @click="doGet(1)">查询</v-btn>
      </div>
    </div>
    <div
      style="border-bottom: 1px solid #ddd;margin-bottom: 30px;margin-top: -5px"
    ></div>
    <v-card flat min-height="300" tile>
      <v-simple-table v-if="refresh">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">用户名</th>
              <th class="text-left">头像</th>
              <th class="text-left">电子邮箱</th>
              <th class="text-left">所在分组</th>
              <th class="text-left">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-show="table_data.length <= 0">
              <td colspan="4" style="text-align: center">
                暂无数据
              </td>
            </tr>
            <tr
              v-for="(item, index) in table_data"
              :key="item.name"
              :style="index % 2 === 0 ? 'background: rgb(242,242,242)' : ''"
            >
              <td>{{ item.username }}</td>
              <td>
                <v-avatar>
                  <img
                    :src="item.avatar"
                    alt="John"
                    style="width: 30px;height: 30px"
                  />
                </v-avatar>
              </td>
              <td>{{ item.email }}</td>
              <td>
                {{ item.groups }}
              </td>
              <td>
                <v-btn color="primary" small @click="editItem(item.id)"
                  >编辑</v-btn
                >
                <!-- <v-btn style="border: 1px solid #1976D2;margin-left: 3px" text  small color="primary">删除</v-btn>-->
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-btn color="primary" small style="margin-top: 10px">新增</v-btn>
    <div v-if="refresh" class="pagination">
      <v-pagination
        v-if="table_data.length > 0"
        v-model="search.page"
        :length="pageCount"
        @input="(e) => doGet(e)"
      ></v-pagination>
    </div>
  </v-container>
</template>
<script>
import { getUserList } from "@/api/users";

export default {
  name: "projectManage",
  mounted() {
    this.doGet();
  },
  methods: {
    doGet(page = this.search.page) {
      getUserList({ ...this.search, page }).then((res) => {
        this.table_data = res.results;
        this.total = res.count;
        this.refresh = false;
        this.$nextTick(() => {
          this.refresh = true;
        });
      });
    },
    changeSearch(e) {
      this.search.email = e;
      this.search.username = e;
    },

    editItem(id) {
      this.$router.push({ name: "edit_user", params: { id } });
    },
  },
  data: () => ({
    refresh: true,
    search: {
      email: "",
      username: "",
      page: 1,
      pagesize: 10,
    },
    total: 0,
    table_data: [],
  }),
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.search.pagesize);
    },
  },
};
</script>
<style lang="stylus" scoped>
.n-title
	font-size 32px

.checkbox >>> .v-label
	font-size 12px
	color #212529
	margin-left -10px !important
	margin-right 10px !important

.pagination
	float: right
</style>
